.App {
  text-align: center;
  height: 100vh;

  background-size:     auto;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;
    color:black
}

img {
  max-width:5vw;
  max-height:5vh;
}

.face img {
  max-width:20vw;
  max-height:20vh;
}

html { font-size: 1.2vh; }
@media (max-height: 576px) {
    html { font-size: 1.8vh; }
    
}





.content {

  color: black;

  padding-left: 20px;
  padding-right: 20px;

}
.content2 {

  color: black;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

}
.content3 {

  color: black;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  

}

.content p {
  margin-top: -5px;

}



iframe {
  height: 15vw;
  width: 60vW;
  position: relative; 
}

.footer  {

  
  font-size: 8px
  
}


.footer p {

  padding-top: 30px;
  font-size: 8px

}

.About  {
  text-align: center;
 text-justify: inter-word;
}

a {
  padding-left: 20px;
  padding-right: 20px;

}



a:hover {
  padding-left: 20px;
  padding-right: 20px;

  filter: blur(1px);
}



input[type="text"]
{
  margin: 10px;
    padding:10px;
    background: transparent;
    border: none;
    border-bottom: 2px solid;
    color: black;
}
input[type="text"]:hover,
input[type="text"]:focus
{
  margin: 10px;
    padding:10px;
    background: transparent;
    border: none;
    border-bottom: 2px solid;
    color: black;
}

input[type="email"]
{
  margin: 10px;
    padding:10px;
    background: transparent;
    border: none;
    border-bottom: 2px solid;
    color: black;
}
input[type="email"]:hover,
input[type="email"]:focus
{
  margin: 10px;
    padding:10px;
    background: transparent;
    border: none;
    border-bottom: 2px solid;
    color: black;
}



input[type="text"]::placeholder { /* Firefox, Chrome, Opera */
    color: black;
}
input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}
input[type="email"]::placeholder { /* Firefox, Chrome, Opera */
    color: black;
}
input[type="email"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}

button{
    background: none;
    padding: 2px 10px;
    border: 2px solid black;
    color: black

}
button:hover{
    background: none;
    padding: 2px 10px;
    border: 2px solid black;
    color: black;
    filter: blur(1px);

}
